import React from 'react';
import Wrapper from '../components/Wrapper'
import { Row, Col } from 'antd';

import './index.less'

export default class IndexPage extends React.Component {

    render() {

        return (
            <Wrapper>
                <Row className="stepWrapper">
                    <Col span={12} offset={6}>
                        <h1>Campaign Tool</h1>
                    </Col>
                </Row>

            </Wrapper>
        );
    }
}

